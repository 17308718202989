import { useContext, useEffect, useState } from 'react'
import "./dashboard.css"
import { AuthContext } from '../../context/authContext'
import { Link } from 'react-router-dom'
import axios from 'axios'


export default function Dashboard(){
    const {logout} = useContext(AuthContext)
    const [count, setcount] = useState([])
    const [page, setpage] = useState(1)
    useEffect(()=>{
        axios
            // .get(`http://localhost:8801/api/kiddofin/v1/general`,{
            .get(`${process.env.REACT_APP_API}/general`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                setcount(response.data);
                // console.log(response.data)
            })
            .catch((error)=>console.error(error));
    },[])
    return(
        <div className='dashboard'>
            <div className='counter'>
                <aside>
                    <h2>No. of Stories</h2>
                    <h1>{count.kiddofin_gallery}</h1>
                </aside>               
                <aside>
                    <h2>No. of Programs</h2>
                    <h1>{count.kiddofin_program}</h1>
                </aside>               
                <aside>
                    <h2>No. of Testimonials</h2>
                    <h1>{count.kiddofin_testimonials}</h1>
                </aside>               
                <aside>
                    <h2>No. of Download Links</h2>
                    <h1>{count.kiddofin_downloads}</h1>
                </aside>               
                
            </div>
            <div className='btns'>
                <Link to="/admin/console/download">Download</Link>
                <Link to="/admin/console/story">Story</Link>
                <Link to="/admin/console/program">Program</Link>
                <Link to="/admin/console/review">Review</Link>
                <Link to="/admin/console/newsletter">Newsletter</Link>
            </div>
        </div>
    )
}
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../cmsList.css'
import { useEffect, useState } from "react";
import axios from "axios";

export default function NewsLetterForm (){
    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.data

    const [newsletter, setnewsletter] = useState({
        title:"",        
        published:"",        
        image_link:"",
        link:""
    })

    const textField = (e) =>{
        const {name, value} = e.target;
        setnewsletter((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const submitForm = (e) =>{
        e.preventDefault()
        
        const formData = new FormData();
        formData.append("title",newsletter.title)        
        formData.append("published",newsletter.published)        
        formData.append("image_link",newsletter.image_link)
        formData.append("link",newsletter.link)

        axios.put(`${process.env.REACT_APP_API}/newsletter`, formData,{
            headers: {
              'x-custom-header': process.env.REACT_APP_ACCESS,
              'Content-Type': 'multipart/form-data',
            }
          })
        .then(res=>{
            if(res.status===200){
                setnewsletter({
                    title:"",        
                    published:"",        
                    image_link:"",
                    link:""
                })
                navigate("/admin/console/dashboard")
            }else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })

        // console.log(date)
    }

    useEffect(()=>{
        if(editData){
            setnewsletter({
                title:editData.title,        
                published:editData.published.split("/").reverse().join("-"),        
                image_link:editData.image_link,
                link:editData.link 
            })
        }
    },[])
    return(
        <div className="cmsForm">
            <span>
                <h2>New Newsletter</h2>
            </span>
            <form onSubmit={submitForm}>
                <div className="cmsInputContainer">
                    <label>Name:</label>
                    <input
                        type="text"
                        name="title"
                        // placeholder="Title"
                        value={newsletter.title}
                        onChange={textField}
                        required
                    />
                </div>                         
                <div className="cmsInputContainer">
                    <label>Published on:</label>
                    <input
                        type="date"
                        name="published"
                        // placeholder="Age"
                        value={newsletter.published}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Image Link:</label>
                    <input
                        type="text"
                        name="image_link"
                        // placeholder="Title"
                        value={newsletter.image_link}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Link:</label>
                    <input
                        type="text"
                        name="link"
                        // placeholder="Title"
                        value={newsletter.link}
                        onChange={textField}
                        required
                    />
                </div>                   
                
                <div className="cmsSubmit">
                    <button
                        type="submit"
                        className="cmsBtn"                        
                    >Submit</button>
                </div>               
            </form>
        </div>
    )
}
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import NavWeb from './components/navWeb';
import Footer from './components/footer';
import About from './pages/about';
import Programs from './pages/programs';
import Source from './pages/source';
import Contact from './pages/contact';
import NotFound from './pages/notFound';
import Gallery from './pages/gallery';
import Login from './cms/login';
import { AuthContext } from './context/authContext';
import { useContext } from 'react';
import Dashboard from './cms/dashboard/dashboard';
import Console from './cms/console';
import NewsletterList from './cms/newsLetter/newsletterList';
import ReviewList from './cms/review/reviewList';
import ProgramList from './cms/program/programList';
import StoryList from './cms/story/storyList';
import DownloadList from './cms/download/downloadList';

import ProgramForm from './cms/program/programForm';
import StoryForm from './cms/story/storyForm';
import DownloadForm from './cms/download/downloadForm';
import ReviewForm from './cms/review/reviewForm';
import NewsLetterForm from './cms/newsLetter/newsLetterForm';



function App() {
  // To Display NavBar
  const location = useLocation()
  const pathname = location.pathname.split('/')[1]

  
  const {isAuth} = useContext(AuthContext)

  return (
    <>
      {pathname!="admin"?<NavWeb/>:null}
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/programme' element={<Programs/>}/>
        <Route path='/downloads' element={<Source/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/gallery' element={<Gallery/>}/>

        <Route path='/*' element={<NotFound/>}/>

        <Route path='/admin/login' 
          element={!isAuth?<Login/>:<Navigate to={'/admin/console/dashboard'}/>}/>
        <Route path='/admin/console' 
          element={!isAuth?<Navigate to={'/admin/login'}/>:<Console/>}>
            <Route path='/admin/console/dashboard' element={<Dashboard/>}/>
            <Route path='/admin/console/download' element={<DownloadList/>}/>
            <Route path='/admin/console/story' element={<StoryList/>}/>
            <Route path='/admin/console/program' element={<ProgramList/>}/>
            <Route path='/admin/console/review' element={<ReviewList/>}/>
            <Route path='/admin/console/newsletter' element={<NewsletterList/>}/>

            <Route path='/admin/console/download' element={<DownloadList/>}/>
            <Route path='/admin/console/story' element={<StoryList/>}/>
            <Route path='/admin/console/program' element={<ProgramList/>}/>
            <Route path='/admin/console/review' element={<ReviewList/>}/>
            <Route path='/admin/console/newsletter' element={<NewsletterList/>}/>

            <Route path='/admin/console/programform' element={<ProgramForm/>}/>
            <Route path='/admin/console/storyform' element={<StoryForm/>}/>
            <Route path='/admin/console/downloadform' element={<DownloadForm/>}/>
            <Route path='/admin/console/reviewform' element={<ReviewForm/>}/>
            <Route path='/admin/console/newsletterform' element={<NewsLetterForm/>}/>
        </Route>  
      </Routes>
      {pathname!="admin"?<Footer/>:null}
    </>
  );
}

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey:process.env.REACT_APP_FIRE_APIKEY,
  authDomain:process.env.REACT_APP_FIRE_AUTHDOMAIN,
  projectId:process.env.REACT_APP_FIRE_PROJECTID,
  storageBucket:process.env.REACT_APP_FIRE_STORAGEBUCKET,
  messagingSenderId:process.env.REACT_APP_FIRE_MESSAGING ,
  appId: process.env.REACT_APP_FIRE_APPID,
  measurementId: process.env.REACT_APP_FIRE_Measure
};

// Initialize Firebase
const app = initializeApp(firebaseConfig); 
// const analytics = getAnalytics(app);

// export const db = getFirestore(app);
export const auth = getAuth(app);
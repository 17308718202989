import { Link, useLocation, useNavigate } from "react-router-dom";
import '../cmsForm.css'
import { useState, useEffect } from "react";
import axios from "axios";
// import image from "../../../public/images/plus1.png"

export default function ProgramForm (){

    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.data

    const [program, setProgram] = useState({
        title:"",
        main_content:"",
        small_content:"",
        bullets:[{key:"", value:""}],
        age:"",
        certification:"",
        home:false,
        image:false,
        link:""
    })
    const [preImage, setPreImage] = useState('../../images/plus1.png')

    const textField = (e) =>{
        const {name, value} = e.target;
        setProgram((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const bulletField = (index,e)=>{
        const { name, value } = e.target;
        const fields = [...program.bullets];
        fields[index][name] = value;
        setProgram({...program,bullets:fields});
    }
    const checkField = (e)=>{
        setProgram({...program,home:e.target.checked})
    }
    const imageField = (e) =>{
        const name = e.target.name;
        const file = e.target.files[0];
        setProgram({...program,
            [name]: file,  
        })

        const reader=new FileReader();
        
        reader.onloadend = () => {
            setPreImage(reader.result);
        };
  
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    // Handler for adding a new input field
    const addInputField = () => {
        // setInputFields([...inputFields, { key: '', value: '' }]);
        setProgram({...program,
            bullets:[...program.bullets, {key:"", value:""}]
        })
    };

    // Handler for removing an input field
    const removeInputField = (index) => {
        const fields = [...program.bullets];
        fields.splice(index, 1);
        setProgram({...program, bullets:fields});
    };

    const submitForm = (e) =>{
        e.preventDefault()
        
        const bullet = {}
        program.bullets.forEach((item)=>{
            if(item.key && item.value){
                bullet[item.key] = item.value.replace(/'/g, "''")
            }
        })
        const formData = new FormData();
        // formData.append("title",program.title)
        // formData.append("main_content",program.main_content)
        // formData.append("small_content",program.small_content)
        // formData.append("bullet",JSON.stringify(bullet))
        // formData.append("age",program.age)
        // formData.append("certification",JSON.stringify(program.certification.split(",")))
        // formData.append("home",program.home)
        // formData.append("image",program.image)
        // formData.append("link",program.link)

        formData.append("title",program.title.replace(/'/g, "''"))
        formData.append("main_content",program.main_content.replace(/'/g, "''"))
        formData.append("small_content",program.small_content.replace(/'/g, "''"))
        formData.append("bullet",JSON.stringify(bullet))
        formData.append("age",program.age.replace(/'/g, "''"))
        formData.append("certification",JSON.stringify(program.certification.replace(/'/g, "''").split(",")))
        formData.append("home",program.home)
        formData.append("image",program.image)
        formData.append("link",program.link.replace(/'/g, "''"))

        axios.post(`${process.env.REACT_APP_API}/programs`, formData,{
            headers: {
              'x-custom-header': process.env.REACT_APP_ACCESS,
              'Content-Type': 'multipart/form-data',
            }
          })
        .then(res=>{
            if(res.status===200){
                setProgram({
                    title:"",
                    main_content:"",
                    small_content:"",
                    bullets:[{key:"", value:""}],
                    age:"",
                    certification:"",
                    home:false,
                    image:"",
                    link:""
                })
                navigate("/admin/console/dashboard")
            }else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })

        
        // console.log(program, JSON.stringify(bullet))
    }

    useEffect(()=>{
        if(editData){
            setProgram({
                title:editData.title,        
                main_content:editData.main_content,
                small_content:editData.small_content,
                bullets:Object.entries(editData.bullets).map(([key, value]) => ({key: key, value: value})),
                age:editData.age,
                certification:editData.certification,
                home:editData.home,
                image:editData.image,
                link:editData.link
            })
            setPreImage(`${process.env.REACT_APP_API_Image}/upload/${editData.image}`);
        }
    },[])
    return(
        <div className="cmsForm">
            <span>
                <h2>New Program</h2>
            </span>
            <form onSubmit={submitForm}>
                <div className="cmsInputContainer">
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        // placeholder="Title"
                        value={program.title}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Main Content:</label>
                    <textarea
                        name="main_content"
                        // placeholder="Main Content"
                        value={program.main_content}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Small Content:</label>
                    <textarea
                        name="small_content"
                        // placeholder="Small Content"
                        value={program.small_content}
                        onChange={textField}
                        required
                    />
                </div>
                {program.bullets.map((item, index)=>(
                    <div className="cmsInputRow" key={index}>
                        <label>Bullet Points:</label>
                        <input
                            type="text"
                            name="key"
                            placeholder="Bullet Title"
                            value={item.key}
                            onChange={(e)=>bulletField(index,e)}
                            required
                        />

                        <input
                            type="text"
                            name="value"
                            placeholder="Bullet Content"
                            value={item.value}
                            onChange={(e)=>bulletField(index,e)}
                            required
                        />
                        <button className="cmsBtn" onClick={()=>removeInputField(index)}>Remove</button>
                    </div>                    
                ))}
                <button onClick={addInputField} className="cmsBtn">Add More Points</button>
                <div className="cmsInputContainer">
                    <label>Age:</label>
                    <input
                        type="text"
                        name="age"
                        // placeholder="Age"
                        value={program.age}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Certification:</label>
                    <input
                        type="text"
                        name="certification"
                        // placeholder="Certification"
                        value={program.certification}
                        onChange={textField}
                        required
                    />
                </div>
                {/* <div className="cmsInputContainer">
                    <label>Link:</label>
                    <input
                        type="text"
                        name="link"
                        value={program.link}
                        onChange={textField}
                        required
                    />
                </div> */}
                <div className="cmsInputContainer">
                    <label>Show in Home:</label>
                    <input
                        type="checkbox"
                        name="home"
                        value={program.home}
                        onChange={checkField}
                        checked={program.home}
                    />                    
                </div>
                <div className="cmsInputContainer">
                    <label>Program Image:</label>                    
                    <div className='FormimageContainer' style={program.image?null:{marginTop:"0%",aspectRatio: "3/1"}}>
                        <div className='uploadImage'>                            
                            <img src={preImage} style={program.image?{width:'80%'}:{width:'30%'}} alt='upload'/>
                        </div>
                        <input
                            type='file'
                            name='image'
                            accept='image/**'
                            onChange={imageField}
                        />
                    </div>
                </div>
                
                <div className="cmsSubmit">
                    <button
                        type="submit"
                        className="cmsBtn"                        
                    >Submit</button>
                </div>               
            </form>
        </div>
    )
}
import { Link, useNavigate, useLocation } from "react-router-dom";
import '../cmsList.css'
import { useState, useEffect } from "react";
import axios from "axios";

export default function ReviewForm (){

    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.data

    const [review, setreview] = useState({
        name:"",        
        content:"",        
        rating:0
    })

    const textField = (e) =>{
        const {name, value} = e.target;
        setreview((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const submitForm = (e) =>{
        e.preventDefault()
        
        const formData = new FormData();
        formData.append("name",review.name)        
        formData.append("content",review.content)        
        formData.append("rating",review.rating)

        axios.post(`${process.env.REACT_APP_API}/testimonials`, formData,{
            headers: {
              'x-custom-header': process.env.REACT_APP_ACCESS,
              'Content-Type': 'multipart/form-data',
            }
          })
        .then(res=>{
            if(res.status===200){
                setreview({
                    name:"",        
                    content:"",        
                    rating:0
                })
                navigate("/admin/console/dashboard")
            }else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })

        // console.log(date)
    }

    useEffect(()=>{
        if(editData){
            setreview({
                name:editData.name,        
                content:editData.content,        
                rating:editData.rating
            })
            // setPreImage(`${process.env.REACT_APP_API_Image}/upload/${editData.image}`);
        }
    },[])
    return(
        <div className="cmsForm">
            <span>
                <h2>New review</h2>
            </span>
            <form onSubmit={submitForm}>
                <div className="cmsInputContainer">
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        // placeholder="Title"
                        value={review.name}
                        onChange={textField}
                        required
                    />
                </div>                         
                <div className="cmsInputContainer">
                    <label>Content:</label>
                    <input
                        type="text"
                        name="content"
                        // placeholder="Age"
                        value={review.content}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Rating:</label>
                    <select
                        type="text"
                        name="rating"
                        // placeholder="Age"
                        value={review.rating}
                        onChange={textField}
                        required
                    >
                        <option hidden>*</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>
                </div>                                                
                
                <div className="cmsSubmit">
                    <button
                        type="submit"
                        className="cmsBtn"                        
                    >Submit</button>
                </div>               
            </form>
        </div>
    )
}
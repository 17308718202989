import React, { createContext, useContext } from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useCookies } from 'react-cookie';


export const AuthContext = createContext();

const AuthProvider = ({children})=>{
    // const {data} = useContext(DataContext)

    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(true);
    // const [user, setUser] = useState(null);
    // const [role, setRole] = useState(null);

    const [cookies, setCookie, removeCookie] = useCookies(['auth']);

      useEffect(() => {
        setIsAuth(cookies.auth?true:false);
    }, [cookies.auth]);
    
    const login = async(user, pass) => {
        try{
            const userdetails = await signInWithEmailAndPassword(auth, user, pass);
            setCookie('auth', true, { path: '/' });
            setCookie('author',user, { path: '/'});

        }catch(err){
            alert("Wrong Credential")
        }
    };

    const logout = () => {
        setIsAuth(false)
        removeCookie("auth", { path: '/' });
        removeCookie("author", { path: '/' });
    };

    return(
        <AuthContext.Provider value={{isAuth, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
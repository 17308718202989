import { Link, useNavigate, useLocation } from "react-router-dom";
import '../cmsList.css'
import { useState, useEffect } from "react";
import axios from "axios";

export default function DownloadForm (){
    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.data

    const [download, setdownload] = useState({
        title:"",        
        link:"",        
        image:""
    })
    const [preImage, setPreImage] = useState('../../images/plus1.png')

    const textField = (e) =>{
        const {name, value} = e.target;
        setdownload((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const imageField = (e) =>{
        const name = e.target.name;
        const file = e.target.files[0];
        setdownload({...download,
            [name]: file,  
        })

        const reader=new FileReader();
        
        reader.onloadend = () => {
            setPreImage(reader.result);
        };
  
        if (file) {
            reader.readAsDataURL(file);
        }
    }


    const submitForm = (e) =>{
        e.preventDefault()
        
        const formData = new FormData();
        formData.append("title",download.title)        
        formData.append("link",download.link)        
        formData.append("image",download.image)

        axios.post(`${process.env.REACT_APP_API}/downloads`, formData,{
            headers: {
              'x-custom-header': process.env.REACT_APP_ACCESS,
              'Content-Type': 'multipart/form-data',
            }
          })
        .then(res=>{
            if(res.status===200){
                setdownload({
                    title:"",                    
                    link:"",        
                    image:""
                })
                navigate("/admin/console/dashboard")
            }else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })

        // console.log(date)
    }

    useEffect(()=>{
        if(editData){
            setdownload({
                title:editData.title,        
                link:editData.link,        
                image:editData.image
            })
            setPreImage(`${process.env.REACT_APP_API_Image}/upload/${editData.image}`);
        }
    },[])
    return(
        <div className="cmsForm">
            <span>
                <h2>New download</h2>
            </span>
            <form onSubmit={submitForm}>
                <div className="cmsInputContainer">
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        // placeholder="Title"
                        value={download.title}
                        onChange={textField}
                        required
                    />
                </div>                         
                <div className="cmsInputContainer">
                    <label>Link:</label>
                    <input
                        type="text"
                        name="link"
                        // placeholder="Age"
                        value={download.link}
                        onChange={textField}
                        required
                    />
                </div>                
                
                <div className="cmsInputContainer">
                    <label>download Image:</label>
                    <div className='FormimageContainer' style={download.image?null:{marginTop:"0%",aspectRatio: "3/1"}}>
                        <div className='uploadImage'>                            
                            <img src={preImage} style={download.image?{width:'80%'}:{width:'30%'}} alt='upload'/>
                        </div>
                        <input
                            type='file'
                            name='image'
                            accept='image/**'
                            onChange={imageField}
                        />
                    </div>
                </div>
                
                <div className="cmsSubmit">
                    <button
                        type="submit"
                        className="cmsBtn"                        
                    >Submit</button>
                </div>               
            </form>
        </div>
    )
}
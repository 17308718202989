import { Link, useNavigate, useLocation } from "react-router-dom";
import '../cmsList.css';
import { useState, useEffect } from "react";
import axios from "axios";

export default function StoryForm (){
    const navigate = useNavigate()
    const location = useLocation()
    const editData = location.state?.data

    const [story, setstory] = useState({
        title:"",
        content:"",
        date:"",
        link:"",        
        image:""
    })
    const [preImage, setPreImage] = useState('../../images/plus1.png')

    const textField = (e) =>{
        const {name, value} = e.target;
        setstory((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const imageField = (e) =>{
        const name = e.target.name;
        const file = e.target.files[0];
        setstory({...story,
            [name]: file,  
        })

        const reader=new FileReader();
        
        reader.onloadend = () => {
            setPreImage(reader.result);
        };
  
        if (file) {
            reader.readAsDataURL(file);
        }

    }

    const submitForm = (e) =>{
        e.preventDefault()
        
        const date = story.date.split("-").reverse().join("-")
        const formData = new FormData();
        formData.append("title",story.title)
        formData.append("content",story.content)
        formData.append("date",date)
        formData.append("link",story.link)        
        formData.append("image",story.image)
        if(editData){
            axios.post(`${process.env.REACT_APP_API}/gallery`, formData,{
                headers: {
                  'x-custom-header': process.env.REACT_APP_ACCESS,
                  'Content-Type': 'multipart/form-data',
                }
              })
            .then(res=>{
                if(res.status===200){
                    setstory({
                        title:"",
                        content:"",
                        date:"",
                        link:"",        
                        image:""
                    })
                    navigate("/admin/console/dashboard")
                }else{
                    console.error('Server returned an error status:', res.status);
                }
            })
            .catch(error=>{
                console.error('Error:',error);
            })
        }else{
            axios.post(`${process.env.REACT_APP_API}/gallery`, formData,{
                headers: {
                  'x-custom-header': process.env.REACT_APP_ACCESS,
                  'Content-Type': 'multipart/form-data',
                }
              })
            .then(res=>{
                if(res.status===200){
                    setstory({
                        title:"",
                        content:"",
                        date:"",
                        link:"",        
                        image:""
                    })
                    navigate("/admin/console/dashboard")
                }else{
                    console.error('Server returned an error status:', res.status);
                }
            })
            .catch(error=>{
                console.error('Error:',error);
            })
        }   

        // console.log(date)
    }

    useEffect(()=>{
        if(editData){
            setstory({
                title:editData.title,
                content:editData.content,
                date:editData.date.split("-").reverse().join("-"),
                link:editData.link,        
                image:editData.image
            })
            setPreImage(`${process.env.REACT_APP_API_Image}/upload/${editData.image}`);
        }
    },[])
    return(
        <div className="cmsForm">
            <span>
                <h2>New story</h2>
            </span>
            <form onSubmit={submitForm}>
                <div className="cmsInputContainer">
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        // placeholder="Title"
                        value={story.title}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>Content:</label>
                    <textarea
                        name="content"
                        // placeholder="Main Content"
                        value={story.content}
                        onChange={textField}
                        required
                    />
                </div>
                <div className="cmsInputContainer">
                    <label>date:</label>
                    <input
                        type="date"
                        name="date"
                        // placeholder="Small Content"
                        value={story.date}
                        onChange={textField}
                        required
                    />
                </div>                
                <div className="cmsInputContainer">
                    <label>Link:</label>
                    <input
                        type="text"
                        name="link"
                        // placeholder="Age"
                        value={story.link}
                        onChange={textField}
                        required
                    />
                </div>                
                
                <div className="cmsInputContainer">
                    <label>story Image:</label>
                    <div className='FormimageContainer' style={story.image?null:{marginTop:"0%",aspectRatio: "3/1"}}>
                        <div className='uploadImage'>                            
                            <img src={preImage} style={story.image?{width:'80%'}:{width:'30%'}} alt='upload'/>
                        </div>
                        <input
                            type='file'
                            name='image'
                            accept='image/**'
                            onChange={imageField}
                        />
                    </div>
                </div>
                
                <div className="cmsSubmit">
                    <button
                        type="submit"
                        className="cmsBtn"                        
                    >Submit</button>
                </div>               
            </form>
        </div>
    )
}
import { Link } from "react-router-dom";
import '../cmsList.css'
import { useEffect, useState } from "react";
import axios from "axios";

export default function NewsletterList (){

    const [newsletter, setnewsletter] = useState([])

    useEffect(()=>{
        axios
            .get(`${process.env.REACT_APP_API}/Newsletter/?id=1`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                setnewsletter(response.data);
            })
            .catch((error)=>console.error(error));
    },[])
    
    return(
        <div className="cmsList">
            <div className='list'>
                <h2>Newsletter List</h2>
                <table>
                    <thead>
                        <tr>
                            <th style={{width:"2.5rem"}}>S.No</th>
                            <th style={{width:"auto"}}>Title</th>
                            <th style={{width:"10rem"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {newsletter.map((item, key)=>(
                            // <>
                            // <tr style={{height:".5rem"}} key={key}></tr>
                            <tr key={key} style={{}}>
                                <td style={{textAlign:"center", paddingTop:".5rem"}}>1</td>
                                <td style={{paddingLeft:"1rem", paddingTop:".5rem"}}>{item.title}</td>
                                <td style={{textAlign:"center",border:"none", paddingTop:".5rem"}}>
                                    <Link className="tableBtn" to="/admin/console/newsletterform" state={{data:item}}>Edit</Link>
                                </td>
                                {/* <td style={{textAlign:"center", border:"none", paddingTop:".5rem"}}>
                                    <Link className="tableBtn">Delete</Link>
                                </td> */}
                            </tr>
                            // </>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <div className='btns'>
                <Link to="/admin/console/newsletterForm">Add Newsletter</Link>
            </div> */}
        </div>
    )
}
import { Btn, Stories, Touch, Underline } from '../components/smallComps'
import './source.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Source (){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    // const downloadSource=[{
    //     name:"Downloads",
    //     link:"https://kiddofin.my.canva.site/downloads"
    // }]

// To Choose NavBar
    const [sourceImg, setSourceImg] = useState('./images/source.png')
    const navSelect = () =>{
        if(window.innerWidth<768){
            setSourceImg('./images/source_Mob.png'  )
        }else{
            setSourceImg('./images/source.png')
        }
    }
    useEffect(() => {
        if (window.innerWidth<768){
            setSourceImg('./images/source_Mob.png')
        }else{
            setSourceImg('./images/source.png')
        }
            window.addEventListener('resize', navSelect);
            window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
            window.removeEventListener('resize', navSelect);
        };
    }, []);


    const [downloadSource, setPopContent] = useState([])
    useEffect(()=>{
        axios
            .get(`${process.env.REACT_APP_API}/downloads/`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                setPopContent(response.data)
            })
            .catch((error)=>console.error(error));

    },[])

    return(
        <>
            <div className='wrapper'>
                <article className='sourceLanding'>
                    <figure>
                        {/* <img src='./images/source.png' alt='soruce Kiddofin'/> */}
                        <img src={sourceImg} alt='soruce Kiddofin'/>
                        <div className='sourceBtn'>
                            <Btn
                            text={"Enroll Now"}
                            link={'#touch'}/>
                        </div>
                    </figure>
                </article>
                <article className='sourceDownload'>
                    <header>
                        <h2>Download Material</h2>
                        <Underline
                        width={40}/>
                    </header>
                    <main>
                        <h3>Name</h3>
                        {downloadSource.map((source)=>(
                            <div className='download'>
                                <figure>
                                    <img src={source?`${process.env.REACT_APP_API_Image}/upload/${source.image}`:""} alt={'what'}/>
                                </figure>
                                <aside>
                                    <p>{source.title}</p>
                                    <a href={source.link}>Download</a>
                                </aside>
                            </div>
                    ))}                                                
                    </main>
                </article>
            </div>
            <div className='wrapper'>
                <Touch/>
            </div>
        </>
    )
}
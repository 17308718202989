import { Btn, Program, Stories, Touch, Underline } from '../components/smallComps'
import './programs.css'
import { useEffect, useState } from 'react'
import axios from 'axios';

export default function Programs(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
    
    // const programList =[{
    //     title:"Finchamps Junior",
    //     image:"1",
    //     content:[
    //         `Finchamps Junior is designed for young learners aged 9-12, introducing them to the basics of financial literacy. Our engaging curriculum covers essential topics such as saving, budgeting, and the value of money through interactive games and activities. This program aims to build a strong foundation in financial education, empowering students with practical skills and knowledge that will serve them throughout their lives.`,            
    //     ],
    //     age:"9 - 12 Years",
    //     certificate:["Finchamps Junior"],
    //     bullets:[
    //         {Focus: "Basics of financial literacy"},
    //         {Topics: "Saving, budgeting, value of money"}, 
    //         {Methodology: "Interactive games and activities"},
    //         {Goal: "Build a strong financial foundation"}
    //     ],
    //     link:'#touch',
    // },{
    //     title:"Finchamps Intermediate",
    //     image:"2",
    //     content:[
    //         `Finchamps Intermediate targets students aged 12-15, focusing on more advanced financial concepts. Participants engage in activities like mock board meetings, investment simulations, and detailed budget planning. This program bridges the gap between basic financial knowledge and practical application, preparing students for real-world financial decision-making.`,            
    //     ],
    //     age:"12 - 15 Years",
    //     certificate:["Finchamps Intermediate"],
    //     bullets:[
    //         {Focus: "Advanced financial concepts"}, 
    //         {Activities: "Mock board meetings, investment simulations, budget planning"},
    //         {Goal: "Bridge the gap between basic knowledge and practical application"}
    //     ],
    //     link:'#touch',
    // },{
    //     title:"Finchamps Advanced",
    //     image:"3",
    //     content:[
    //         `Finchamps Advanced caters to high school students, aged 15-18, providing in-depth financial education that includes topics such as advanced investment strategies, entrepreneurship, and financial planning. This comprehensive program equips students with the skills needed to navigate complex financial landscapes, encouraging them to become financially independent and responsible adults.`,            
    //     ],
    //     age:"15 - 18 Years",
    //     certificate:["Intermediate Financial Whiz"],
    //     bullets:[
    //         {Focus: "In-depth financial education"}, 
    //         {Topics: "Advanced investment strategies, entrepreneurship, financial planning"},
    //         {Goal: "Equip students with skills for real-world financial decision-making"}
    //     ],
    //     link:'#touch',
    // },{
    //     title:"5-Hour Workshop",
    //     image:"4",
    //     content:[
    //         `Our 5-hour intensive workshops offer a deep dive into specific financial topics. Tailored for different age groups, these workshops cover everything from the basics of personal finance to advanced investment techniques. Participants leave with practical knowledge and actionable insights that they can immediately apply in their daily lives.`,
    //     ],
    //     age:"No Restriction",
    //     certificate:["Senior Financial Expert"],
    //     bullets:[
    //         {Duration: "5 hours"},
    //         {Focus: "Intensive coverage of specific financial topics"},
    //         {Audience: "Different age groups"},
    //         {Outcome: "Practical knowledge and actionable insights"}
    //     ],
    //     link:'#touch',
    // },{
    //     title:"Online Classes",
    //     image:"5",
    //     content:[
    //         `KiddoFin’s online classes provide flexible, comprehensive financial education accessible from anywhere. Our expert instructors deliver interactive lessons covering a wide range of topics suitable for various age groups. Whether you're looking to build foundational knowledge or explore advanced financial strategies, our online classes are designed to fit your schedule and learning needs.`,
    //     ],
    //     age:"09 - 18 years",
    //     certificate:["Digital certificate"],
    //     bullets:[
    //         {Format: "Online, flexible schedule 5-7 Hours"},
    //         {Focus: "Comprehensive financial education"},
    //         {Instruction: "Expert-led interactive lessons"},
    //         {Topics: "Range from foundational knowledge to advanced strategies"},
    //         {Goal: "Fit various learning needs and schedules"}
    //     ],
    //     link:'#touch',
    // },]

    // API
   
    const [programList, setprogramList] = useState([])
    const [newsletter, setNewsletter] = useState([])
    const [page, setpage] = useState(1)
    useEffect(()=>{
        axios
            .get(`${process.env.REACT_APP_API}/programs/`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                setprogramList(response.data)
            })
            .catch((error)=>console.error(error));

    },[])
    const programEvents =[]

    return(
        <>
            <article className='programLanding'>
                <figure>
                    <img src='./images/programLanding.svg' alt='wave Kiddofin'/>
                </figure>
                <main>
                    <header>
                        <h1>"Equip young minds with financial<br/>knowledge today, 
                            build a prosperous and<br/>self-reliant India tomorrow."</h1>
                    </header>
                    <aside>
                        <Btn
                        text={"Enroll Now"}
                        link={"#touch"}/>
                    </aside>
                </main>                
            </article>
            <div className='wrapper'>
                <article className='programsContainer'>
                    <header>
                        <h2>Courses We offer</h2>
                        <Underline
                        width={40}/>
                    </header>
                    <div className='programs'>
                        {programList.map((program, key)=>(<Program props={program} key={key}/>))}                        
                        {/* <Program props={programList[0]}/> */}
                        
                    </div>
                </article>
                {/* <div className='classSpecs'>
                    <h2>Classroom Sessions:</h2>
                    <ul>
                        <li>Engaging lectures and discussions on various financial concepts.</li>
                        <li>Interactive activities to reinforce learning and ensure practical understanding.</li>
                    </ul>
                    <h3>Group Activities and Team Learning:</h3>
                    <ul>
                        <li>Collaborative projects and presentations to enhance teamwork and communication skills.</li>
                        <li>Financial games and simulations to provide hands-on experience.</li>
                    </ul>
                    <h3>Guest Lectures:</h3>
                    <ul>
                        <li>Insights from industry professionals and financial experts.</li>
                        <li>Interactive Q&A sessions to address students' queries and provide real-world perspectives.</li>
                    </ul>
                    <h3>Certification:</h3>
                    <ul>
                        <li>A structured assessment process to evaluate students' understanding and knowledge.</li>
                        <li>Certificates awarded upon successful completion of the program, adding value to students' academic portfolios.</li>
                    </ul>
                    <h3>Quiz Competition:</h3>
                    <ul>
                        <li>A fun and competitive way to reinforce learning.</li>
                        <li>Encourages students to review and apply their knowledge in a quiz format.</li>
                    </ul>
                    <h3>Specific Concepts Covered:</h3>
                    <ul>
                        <li>Basics of money management, budgeting, and saving.</li>
                        <li>Understanding banking operations, types of bank accounts, and the role of RBI.</li>
                        <li>Introduction to insurance, its types, and importance.</li>
                        <li>Fundamentals of investments, stock markets, and portfolio management.</li>
                        <li>Entrepreneurship, business planning, and digital finance.</li>
                    </ul>
                    <h3>Grade-Specific Modules:</h3>
                    <ul>
                        <li>Grades 6-8: Focuses on foundational financial concepts, including money matters, basic banking, budgeting, and introductory investment strategies.</li>
                        <li>Grades 9-10: Delves deeper into advanced financial topics like taxation, stock markets, e-commerce, and financial sector reforms.</li>
                        <li>Grades 11-12: Covers comprehensive financial planning, retirement planning, insurance policies, and preparation for financial careers.</li>
                    </ul>
                </div> */}                
                {/* <div className='classSpecs'>
                    <h2>Structure:</h2>
                    <h3>Introduction to Financial Literacy:</h3>
                    <ul>
                        <li>Overview of basic financial terms and concepts.</li>
                        <li>Understanding the importance of financial literacy in everyday life.</li>
                    </ul>
                    <h3>Banking Basics:</h3>
                    <ul>
                        <li>Introduction to the banking system and different types of bank accounts.</li>
                        <li>Practical exercises on how to manage a bank account, including deposits and withdrawals.</li>
                    </ul>
                    <h3>Budgeting and Saving:</h3>
                    <ul>
                        <li>Importance of budgeting and saving money.</li>
                        <li>Hands-on activity to create a personal or event budget.</li>
                    </ul>
                    <h3>Investment Fundamentals:</h3>
                    <ul>
                        <li>Basics of different investment options and their risks.</li>
                        <li>Interactive session on creating a mock investment portfolio.</li>
                    </ul>
                    <h3>Digital Finance and Online Safety:</h3>
                    <ul>
                        <li>Introduction to online banking and digital transactions.</li>
                        <li>Tips on maintaining cybersecurity and avoiding financial fraud.</li>
                    </ul>
                    <h3>Interactive Elements:</h3>
                    <ul>
                        <li>Role-plays and simulations to provide practical understanding.</li>
                        <li>Group discussions and presentations to encourage active participation.</li>
                        <li>Quizzes and assessments to test knowledge and understanding.</li>
                    </ul>
                    <h3>Benefits:</h3>
                    <ul>
                        <li>Provides a strong foundation in financial literacy within a short time frame.</li>
                        <li>Engages students through interactive and practical learning methods.</li>
                        <li>Prepares students to manage their personal finances effectively.</li>
                    </ul>
                    <h3>Target Audience:</h3>
                    <ul>
                        <li>Middle and high school students looking for a quick, comprehensive financial education.</li>
                        <li>Educators seeking to provide students with essential financial skills in a condensed format.</li>
                        <li>These programs are designed to cater to different learning needs and schedules, ensuring that students receive valuable financial education that prepares them for the future.</li>
                    </ul>
                </div> */}
{/* Spl Event ========================================================== */}
                {programEvents.length>0?<article className='programsContainer'>
                    <header>
                        <h2>Special Event</h2>
                        <Underline
                        width={40}/>
                    </header>
                    <div className='programs'>
                        {programEvents.map((program)=>(<Program props={program}/>))}                        
                    </div>
                </article>:null}
            </div>
            <div className='wrapper'>
                <Touch/>
            </div>
        </>
    )
}
import { useContext, useState } from 'react'
import './login.css'
import { AuthContext } from '../context/authContext'

export default function Login(){

    const {login} = useContext(AuthContext)

    const [user, setUser] = useState()
    const [pass, setPass] = useState()
    const [showPass, setShowPass] = useState(false)

    const loginSubmit = async(e)=>{
        e.preventDefault()
        login(user,pass)
    }
    return(
        <div className='wrapper'>
            <div className='loginScreen'>                
                <form className='loginContainer' onSubmit={loginSubmit}>
                    <h1>Kiddofin</h1>
                    <div className='loginInput'>
                        <input
                            type='text'
                            name='user'
                            placeholder='Username'
                            value={user}
                            onChange={(e)=>setUser(e.target.value)}
                            required
                        />
                    </div>
                    <div className='loginInput'>
                        <input
                            type={showPass?'text':'password'}
                            name='pass'
                            placeholder='Password'
                            value={pass}
                            onChange={(e)=>setPass(e.target.value)}
                            required
                        />
                    </div>
                    <div className='loginInput checkboxLogin'>
                        <input
                            type='checkbox'
                            value={showPass}
                            onChange={(e)=>setShowPass(!showPass)}
                        />
                        <label>Show Password</label>
                    </div>
                    <div className='loginInput submitLogin'>
                        <input
                            type='submit'
                            value="Submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
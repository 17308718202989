import { Link } from "react-router-dom";
import '../cmsList.css'
import { useEffect, useState } from "react";
import axios from "axios";

export default function DownloadList (){

    const [download, setdownload] = useState([])
    const [page, setpage] = useState(1)
    useEffect(()=>{
        axios
            .get(`${process.env.REACT_APP_API}/downloads?page=${page}`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                setdownload(response.data);
            })
            .catch((error)=>console.error(error));
    },[])

    const deleteItem = (e, id)=>{        
        e.preventDefault()
        axios
            .delete(`${process.env.REACT_APP_API}/downloads/${id}`,{
                headers:{
                    'x-custom-header': process.env.REACT_APP_ACCESS
                }
            })
            .then((response)=>{
                if(response.status===200){
                    window.location.reload();
                }
            })
            .catch((error)=>console.error(error));
    }
    
    return(
        <div className="cmsList">
            <div className='list'>
                <h2>Download Link List</h2>
                <table>
                    <thead>
                        <tr>
                            <th style={{width:"2.5rem"}}>S.No</th>
                            <th style={{width:"auto"}}>Title</th>
                            <th colSpan={1} style={{width:"10rem"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {download.map((item, key)=>(
                            // <>
                            // <tr style={{height:".5rem"}} key={key}></tr>
                            <tr key={key} style={{}}>
                                <td style={{textAlign:"center", paddingTop:".5rem"}}>{key+1}</td>
                                <td style={{textAlign:"center", paddingLeft:"1rem", paddingTop:".5rem"}}>{item.title}</td>                                
                                {/* <td style={{textAlign:"center",border:"none", paddingTop:".5rem"}}>
                                    <Link className="tableBtn" to="/admin/console/downloadform" state={{data:item}}>Edit</Link>
                                </td> */}
                                <td style={{textAlign:"center", border:"none", paddingTop:".5rem"}}>
                                    <Link className="tableBtn" onClick={(e)=>deleteItem(e,item.id)}>Delete</Link>
                                </td>
                            </tr>
                            // </>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='btns'>
                <Link to="/admin/console/downloadForm">Add Download Link</Link>
            </div>
        </div>
    )
}
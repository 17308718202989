import { useContext } from 'react'
import { AuthContext } from '../context/authContext'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

export default function Console(){
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split("/")

    const {logout} = useContext(AuthContext)
    return(
        <div className='wrapper cmsWrapper'>
            <header>
                <h2>Kiddofin</h2>
                <div style={{display:"flex", gap:"2rem"}}>
                    <button onClick={()=>navigate('/admin/console/dashboard')} style={path[3]!=="dashboard"?{display:"block"}:{display:"none"}}>Dashboard</button>
                    <button onClick={()=>logout()}>Log Out</button>                
                </div>                
            </header>
            <Outlet/>
        </div>
    )
}